interface IProps {
  firstPlayerScore: number;
  secondPlayerScore: number;
}

export function MatchResult({
  firstPlayerScore,
  secondPlayerScore,
}: Readonly<IProps>) {
  return (
    <div className="result">
      <p className={firstPlayerScore > secondPlayerScore ? "bold" : ""}>
        {firstPlayerScore}
      </p>
      <p className={firstPlayerScore < secondPlayerScore ? "bold" : ""}>
        {secondPlayerScore}
      </p>
    </div>
  );
}
