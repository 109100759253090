import { format } from "date-fns";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { Match } from "../../common/types";
import { EventAttributes, createEvent } from "ics";

interface IProps {
  match: Match;
}

export function MatchSchedule({ match }: Readonly<IProps>) {
  const downloadMatchEvent = async () => {
    const filename = `${match.first_player.name.replace(
      " ",
      "_"
    )}-${match.second_player.name.replace(" ", "_")}.ics`;

    const file: File = await new Promise((resolve, reject) =>
      createMatchEvent(resolve, reject, filename)
    );

    const url = URL.createObjectURL(file);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
  };

  const createMatchEvent = (
    resolve: (value: File | PromiseLike<File>) => void,
    reject: (error: unknown) => void,
    filename: string
  ) => {
    const event: EventAttributes = {
      start: match.date?.getTime() ?? 0,
      title: `${match.first_player.name} - ${match.second_player.name}`,
      duration: { hours: 1 },
      location: `${match.court}. igrišče`,
      geo: { lat: 46.285641, lon: 15.198058 },
    };

    createEvent(event, (error, value) => {
      if (error) {
        reject(error);
      }

      resolve(new File([value], filename, { type: "text/calendar" }));
    });
  };

  return (
    <div className="schedule">
      <div className="calendar-icon">
        <CalendarIcon width={32} height={32} onClick={downloadMatchEvent} />
      </div>
      <div className="date">
        <p>{match.date ? format(match.date, "H:mm") : ""}</p>
        <p>
          <span className={`badge badge-${match.court}`}>
            {match.court}. igrišče
          </span>
        </p>
      </div>
    </div>
  );
}
