import { Match as MatchI } from "../../common/types";
import { MatchResult } from "./MatchResult";
import { MatchSchedule } from "./MatchSchedule";

interface IProps {
  match: MatchI;
}

export function Match({ match }: Readonly<IProps>): JSX.Element {
  return (
    <div className="match">
      <div className="players">
        <p
          className={
            (match.first_player.score ?? 0) > (match.second_player.score ?? 0)
              ? "bold"
              : ""
          }
        >
          {match.first_player.name}
        </p>
        <p
          className={
            (match.first_player.score ?? 0) < (match.second_player.score ?? 0)
              ? "bold"
              : ""
          }
        >
          {match.second_player.name}
        </p>
      </div>
      {match.played ? (
        <MatchResult
          firstPlayerScore={match.first_player.score ?? 0}
          secondPlayerScore={match.second_player.score ?? 0}
        />
      ) : (
        <MatchSchedule match={match} />
      )}
    </div>
  );
}
